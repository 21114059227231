import React, { Fragment } from "react"

/* Import Global Components */
import SEO from "~components/seo"

/* Import Local Style(s) */
import "./404.css"

const NotFoundPage = () => (
  <Fragment>
    <SEO title="404" />
    <div className="page 404">404</div>
  </Fragment>
)

export default NotFoundPage
